import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import DataGrid from "react-data-grid";
import { FilterContext } from '../Contexts/FilterContext';
import { ToastContext } from '../Contexts/ToastProvider';
import PaginationComponent from '../PaginationComponent/PaginationComponent';
import { ColumnFilterRenderer } from '../Renderers/ColumnFilterRenderer';
import { copyValueToClipboard, filterRows, inputStopPropagation, sortComps } from '../utils/GridUtils';
import { exportToXlsx } from '../utils/exportUtils';
import './styles.scss';

function createFilterProps(columnDefinitions, enabled) {
    const filterProps = { enabled };
    if (columnDefinitions !== null) {
        columnDefinitions.forEach(colDef => {
            if (colDef.filterable === true) {
                filterProps[colDef.key] = '';
            }
        });
    }
    return filterProps;
}

function setColumnFilters(filters, colKey, e, setFilters) {
    return setFilters({
        ...filters,
        [colKey]: e.target.value
    });
}

function addFilterToColumnHeader(columns, setFilters) {
    const newColumns = [];
    columns.forEach((col) => {
        if (col.sortable || col.filterable) {
            col.headerCellClass = col.filterable ? 'filter-cell' : '';
            col.headerRenderer = (p) => (
                <ColumnFilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <input
                            key={col.key}
                            {...rest}
                            className='filter-renderer'
                            value={filters[col.key]}
                            onChange={(e) =>
                                setColumnFilters(filters, col.key, e, setFilters)
                            }
                            placeholder='Type to filter'
                            disabled={!filters.enabled}
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </ColumnFilterRenderer>
            )
        }
        newColumns.push(col);
    });
    return newColumns;
};

export default function GenericGridComponent({ columns, data, customExportMethod, fileName, itemsName, selectedCurrentPage, onCurrentPageSelect, enableGridToCSV = false, useCustomExportMethod = false, enablePagination = true, pageSize = 10 }) {
    const [showHeaderFilters, setShowHeaderFilters] = useState(false);
    const [sortColumns, setSortColumns] = useState([]);
    const [filters, setFilters] = useState(createFilterProps(columns, true));
    const [filterColumns, setFilterColumns] = useState(addFilterToColumnHeader(columns, setFilters));
    const [paginatedData, setPaginatedData] = useState([]);
    const [selectedPageSize, setSelectedPageSize] = useState(pageSize);
    const { showToast } = useContext(ToastContext);

    const toggleHeaderFilters = () => {
        setShowHeaderFilters(!showHeaderFilters);
        setFilterColumns(addFilterToColumnHeader(columns, setFilters));
    }

    const sortedRows = useMemo(() => {

        const filteredRows = filterRows(filters, data);
        if (sortColumns.length === 0) {
            if (filters !== null) {
                return filteredRows;
            } else {
                return data;
            }
        }
        return sortComps(filteredRows, sortColumns, filterColumns);
    }, [sortColumns, data, filterColumns, filters]);

    useEffect(() => {
        setFilters(createFilterProps(filterColumns, showHeaderFilters));
    }, [filterColumns, showHeaderFilters]);

    useEffect(() => {
        setSortColumns([])
    }, [data]);

    const onSortColumnChange = useCallback((sortColumns) => {
        setSortColumns(sortColumns.slice(-2));
    }, []);

    const onChangePaginatedData = useCallback((paginatedData) => {
        setPaginatedData(paginatedData);
    }, [])

    const dataGrid = (
        <DataGrid
            className={showHeaderFilters ? 'rdg-light filter-container' : 'rdg-light'}
            headerRowHeight={showHeaderFilters ? 90 : 35}
            columns={filterColumns}
            defaultColumnOptions={{
                sortable: true,
                resizable: true
            }}
            sortColumns={sortColumns}
            onSortColumnsChange={onSortColumnChange}
            rows={paginatedData}
            rowHeight={60}
            onCopy={(e) => copyValueToClipboard(e, showToast)}
        />
    );

    const handleExport = async () => {
        if (useCustomExportMethod && customExportMethod) {
            customExportMethod();
        } else {
            exportToXlsx(dataGrid, fileName);
        }
    }

    return (
        <div className='data-content'>
            <div className="grid-container">
                <FilterContext.Provider value={filters}>
                    <div className="d-flex justify-content-start mt-2">
                        {data.length > 0 ? <Button title='Toggle Grid Header Filters' variant='secondary' onClick={toggleHeaderFilters} style={{ padding: "0.25rem", maxWidth: "2rem", marginRight: "0.2rem" }}>
                            {showHeaderFilters ? <i className="fa fa-sort-amount-up-alt" style={{ padding: "0.25rem" }}></i> : <i className="fa fa-sort-amount-down-alt" style={{ padding: "0.25rem" }}></i>}
                        </Button> : null}
                        {enableGridToCSV && data.length > 0 ? <Button title='Export grid to excel' variant='secondary' onClick={() => handleExport()} style={{ padding: "0.25rem", marginRight: "0.2rem", maxWidth: "2rem" }}>
                            <i className="fa fa-file-csv" style={{ padding: "0.25rem" }}></i>
                        </Button> : null}
                    </div>
                    {data.length > 0 ? dataGrid : <Alert variant="info">No {itemsName} found</Alert>}
                </FilterContext.Provider>
            </div>
            {enablePagination &&
                <PaginationComponent
                    filteredData={sortedRows}
                    selectedCurrentPage={selectedCurrentPage}
                    onChangePaginatedData={onChangePaginatedData}
                    onCurrentPageSelect={(pageNumber) => onCurrentPageSelect(pageNumber)}
                    itemsName={itemsName}
                    selectedPageSize={selectedPageSize}
                    onChangeSelectedPageSize={(pageSize) => setSelectedPageSize(pageSize)}
                />}
        </div>
    );
}

GenericGridComponent.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    enableGridToCSV: PropTypes.bool,
    useCustomExportMethod: PropTypes.bool,
    customExportMethod: PropTypes.func,
    fileName: PropTypes.string,
    itemsName: PropTypes.string.isRequired,
    selectedCurrentPage: PropTypes.number,
    onCurrentPageSelect: PropTypes.func,
    enablePagination: PropTypes.bool,
    pageSize: PropTypes.number
}